import React  from "react";
import {Text,Heading,Box, SimpleGrid, Flex} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo'

export default function Gottschalk(){
    return(
    <motion.div 
    initial={{opacity:0,paddingBottom:"100px"}}
    animate={{
      opacity:1
    }}
    transition={{duration:.1,delay:.1,ease:"circInOut"}}
    >   
    <MySeo title = "Das Schicksal der jüdischen Familie Gottschalk aus Oberwesel" description="Ausstellungsparcours 2022" />
         <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid gap={4} columns={[1,1,2]}>
        <Box>
            <Heading variant="utitel" >Kirchstraße 85<br/>
            Erna, Max und Alfred Gottschalk
            </Heading>
            
            <Text variant="solid">
            Die Familie Gottschalk stammt ursprünglich nicht aus Oberwesel. Der 1930 in Koblenz geborene und 2009 in Cincinnati verstorbene Rabbiner Dr. Alfred Gottschalk ist in der Kirchstraße 85 aufgewachsen. Er gilt als führender Vertreter des Reformjudentums. Seine Urgroßmutter Helene Wolff, geb. Mayer, stammt aus Oberwesel. Max Gottschalk (*1889 in Niederzissen) flieht 1938 Hals über Kopf nach einer Warnung nach Holland und bekommt ein Schiff nach New York. 1939 kann seine Frau Erna mit Alfred nachkommen.
            </Text>
            <Heading variant="utitel">14. Dezember 1929</Heading>
            <Text variant="solid">Nach seiner Hochzeit mir Erna Gerson, geb. Trum, meldet Max Gottschalk in Oberwesel einen Viehhandel an.</Text>
            <Heading variant="utitel">Oktober 1935</Heading>
            <Text variant="solid">Der antisemitische Stürmer hetzt in einem Rufmordartikel gegen Gustav Gerson und Max Gottschalk.</Text>
            <Heading variant="utitel">9. März 1938</Heading>
            <Text variant="solid">Nach einer Warnung des Polizisten Otto Lindstedt, flieht Max Gottschalk mit einem Schiff nach Rotterdam. Als er ein Visum hat, fährt er mit der »Veendam« am 15.10. nach New York, wo er von Arthur Gerson erwartet wird.</Text>
            <Heading variant="utitel">10. November 1938</Heading>
            <Text variant="solid">Der 8-jährige Alfred sieht die geschändete Synagoge auf dem Schaarplatz und hilft seinem Großvater Gustav, Teile der Thorarollen und Gebetbücher aus dem Bach zu retten. Dies verändert sein Leben.</Text>
            <Heading variant="utitel">15. November 1938</Heading>
            <Text variant="solid">Alfred wird, wie Ruth Lichtenstein, von der SA aus der Klasse geholt und darf die Schule nicht weiter besuchen.</Text>
            <Heading variant="utitel">11. Juni 1939</Heading>
            <Text variant="solid">Erna Gottschalk flieht mit Alfred an Bord des Dampfers »Manhattan« von Hamburg nach New York.</Text>
            <Heading variant="utitel">27. April 1942</Heading>
            <Text variant="solid">akob und Minna Gottschalk, Alfreds Großeltern, werden aus Niederzissen nach Theresienstadt deportiert und von dort 19.09.1942 in das Vernichtungslager Treblinka verschleppt, wo sie ermordet werden.
           </Text>
            <Heading variant="utitel">1943–1948 </Heading>
            <Text variant="solid">Alfred Gottschalk besucht die Junior High School und anschließend bis 1948 die High School in Brooklyn.</Text>
            <Heading variant="utitel">26. September 1946</Heading>
            <Text variant="solid">Max Gottschalk stirbt mit 48 Jahren. Er hat zuerst in einer Lumpenfabrik und während des Krieges in einer Flugzeugfabrik gearbeitet. Seine Witwe Erna heiratet einige Jahre später Thilo Nußbaum.</Text>
            <Heading variant="utitel">1948–1952</Heading>
            <Text variant="solid">Nach der High-School besucht Alfred Gottschalk das College, arbeitet nebenher. Er entscheidet sich für den Beruf des Rabbiners, besucht das Hebrew Union College in New York und später das College in Cincinnati.</Text>
            <Heading variant="utitel">1953–1965</Heading>
            <Text variant="solid">Alfred Gottschalk heiratet 1953 Gina Schrag (*1933). Mit den Kindern Marc und Rachel ziehen sie 1957 nach Los Angeles. 1957 erlangt Alfred Gottschalk die Rabbinerwürde. Er promoviert 1965 am Hebrew Union College.</Text>
            <Heading variant="utitel">1971–2000</Heading>
            <Text variant="solid">Alfred Gottschalk ist Präsident bzw. Kanzler des Hebrew Union College. Er hilft beim Aufbau des Holocaust Memorial Museums in Washington. 1977 heiratet er Deanna Zeff Frank. Erna Gottschalk-Nußbaum stirbt am 21.12.1990.</Text>
            <Heading>5. September 2006</Heading>
            <Text variant="solid">abbi Gottschalk besucht Oberwesel zur Errichtung eines Gedenksteins auf dem Schaarplatz: »Danken wir Gott, dass er uns diesen Tag der Erinnerung und eine Zeit der Versöhnung ermöglicht hat«. Den Großeltern Henriette und Gustav Gerson lässt er einen Grabstein setzen. Er stirbt am 12.09.2009 in Cincinnati.</Text>
            </Box>
            <Box>
                <Box p="3">
                    <StaticImage src="../images/solo/gottschalk-mutter.jpg" alt="Alfred Gottschalk mit Mutter Eerna und Tante Else Trum" />
                    <Text variant="Bild">Alfred Gottschalk mit Mutter Eerna und Tante Else Trum, 24.04.1939 </Text>
                </Box>
               <Flex flexWrap="wrap" >
                <Box p="1">
                    <StaticImage src="../images/solo/Max-Gottschalk-Rotterdam.jpg" alt="Max Gottschalk in Amsterdam" />
                    <Text variant="Bild">Max Gottschalk in Amsterdam</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/Rabbi-Gottschalk.jpeg" alt="Rabbi Dr. Alfred Gottschalk" />
                    <Text variant="Bild">Rabbi Dr. Alfred Gottschalk</Text>
                </Box>
                </Flex>
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}